<template>
    <div ref="chartEl"></div>
</template>

<script setup lang="ts">
import type { DataObject } from 'o365-dataobject';
import { importUtils, logger } from 'o365-utils';
import { ref, nextTick, onBeforeUnmount, watch } from 'vue';
import { useDataObjectEventListener} from 'o365-vue-utils';

export interface IProps {
    dataObject: DataObject,
    options: Record<string, any>,
    loadDataObject?: boolean,
};

const props = defineProps<IProps>();
const chartEl = ref<HTMLElement>();

// const cleanupTokens: (() => void)[] = [];
const moduleLoaded = ref(false);

watch(props.options, (newOptions) => {
    if (chartInstance == null) { return; }
    chartInstance.update(applyDataToOptions(props.options), true, true);
}, {
    deep: true
});

loadHighCharts().then(() => {
    moduleLoaded.value = true;
    return nextTick();
}).then(() => {
    initChart();
}).catch(ex => {
    logger.error(ex);
});

useDataObjectEventListener(props.dataObject, 'DataLoaded', () => {
    if (chartInstance == null) { return; }
    chartInstance.update(applyDataToOptions(props.options), true, true);
});

function applyDataToOptions(pOptions: any) {
    const options = JSON.parse(JSON.stringify(pOptions));
    const categoryColumn = options.categoryColumn.name;
    delete options.categoryColumn;
    // const seriesColumns = options.seriesColumns;
    delete options.seriesColumns;
    if (options.series == null || categoryColumn == null) { return options; }

    if (options.xAxis == null) { options.xAxis = {}};

    if (options.chart.type == 'pie') {
        for (let i = 0; i < props.dataObject.data.length; i++) {
            options.series[0].data.push({
                name: props.dataObject.data[i][categoryColumn],
                y: props.dataObject.data[i][options.series[0].name]
            })
        }
        options.series[0].name = categoryColumn;
    }
    else{
        options.xAxis.categories = props.dataObject.data.map(item => item[categoryColumn]);

        options.series.forEach(seriesColumn => {
            seriesColumn.data = props.dataObject.data.map(item => item[seriesColumn.name]); 

            if (seriesColumn.animation == null) {
                seriesColumn.animation = {
                    duration: 500,
                    easing: 'easeOutBounce'
                };
            }
        });
    }

    return options;
}

let chartInstance: any = null;
async function initChart() {
    // if (chartEl.value == null || props.options == null) { return; }
    if (chartInstance) { chartInstance.destroy(); }
    if (props.loadDataObject) { await props.dataObject.load(); }
    chartInstance = Highcharts.chart(chartEl.value, applyDataToOptions(props.options));
}


onBeforeUnmount(() => {
    if (chartInstance) {
        chartInstance.destroy();
        chartInstance = null;
    }
});

</script>

<script lang="ts">
let HIGHCHARTS_INIT_PROMISE: Promise<void> | null = null;

async function loadHighCharts() {
    console.log("here");
    if (HIGHCHARTS_INIT_PROMISE == null) {
        let resolve = () => { };
        let reject = (pEx: Error) => { };
        HIGHCHARTS_INIT_PROMISE = new Promise<void>((res, rej) => {
            resolve = res;
            reject = rej;
        });

        importUtils.loadScript('highcharts').then(() => {
            return importUtils.loadScript('highcharts/code/modules/exporting.js');
        }).then(() => {
            return importUtils.loadStyle('highcharts/code/modules/offline-exporting.js');
        }).then(() => {
            resolve();
        }).catch((ex) => {
            reject(ex);
        });

        return HIGHCHARTS_INIT_PROMISE;
    } else {
        return HIGHCHARTS_INIT_PROMISE;
    }
}
</script>